<template>
  <el-form
    :model="form"
    ref="form"
    label-width="100px"
    class="multiple-ruleForm"
    label-position="top"
    size="small"
    :disabled="submitting"
  >
    <!-- :ref="`input${index}`" @keyup.enter.native="addRow(`input${index + 1}`)" -->
    <el-row :gutter="10">
      <el-col
        :sm="24"
        :md="24"
        v-for="(item, index) in form.items"
        :key="item.key"
      >
        <el-card
          class="box-card"
          :body-style="{
            padding: ' 0 10px',
          }"
        >
          <div slot="header" class="clearfix">
            条目{{ index + 1 }}
            <el-button
              class="text-danger"
              style="float: right; padding: 3px 0"
              type="text"
              @click="onDeleteRow(index)"
              :disabled="submitting || form.items.length <= 1"
              >删除</el-button
            >
          </div>
          <el-row :gutter="10">
            <el-col :sm="24" :md="6">
              <el-form-item
                label-position="left"
                label="中文名称"
                :prop="'items.' + index + '.nameCn'"
                :rules="[
                  {
                    required: true,
                    message: '请输入中文名称',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :disabled="submitting"
                  v-model="item.nameCn"
                  clearable
                  :ref="`input${index}`"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="6">
              <el-form-item
                label-position="left"
                label="英文名称"
                :prop="'items.' + index + '.nameEn'"
                :rules="[
                  {
                    required: true,
                    message: '请输入英文名称',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :disabled="submitting"
                  v-model.trim="item.nameEn"
                  clearable
                  :ref="`input${index}`"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="6">
              <el-form-item
                label="次序(数值越大越靠前)"
                :prop="'items.' + index + '.seq'"
                :rules="[
                  { required: true, message: '请输入次序', trigger: 'blur' },
                ]"
              >
                <el-input
                  :disabled="submitting"
                  v-model.trim="item.seq"
                  clearable
                  :ref="`input${index}`"
                  placeholder="请输入"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="6">
              <el-form-item
                label="图片"
                :prop="'items.' + index + '.menuImageUrl'"
                :rules="[
                  { required: false, message: '请选择图片', trigger: 'blur' },
                ]"
              >
                <SingleImgUpload class="icon-img" v-model="item.menuImageUrl" />
              </el-form-item>
            </el-col>
          </el-row> </el-card
      ></el-col>
    </el-row>

    <el-form-item style="text-align: right; margin-top: 30px">
      <!-- <el-button @click="onCancel" :disabled="submitting">关 闭</el-button> -->
      <el-button @click="addRow" :disabled="submitting">新增一行</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { createUniqueString } from "@/utils/common";
import { PostMosaicTechniques } from "../api";
import { ShowApiError } from "@/request/error";
import SingleImgUpload from "@/components/SingleImgUpload";
export default {
  components: { SingleImgUpload },
  data() {
    return {
      submitting: false,
      focusIndex: 0,
      form: {
        items: [
          {
            nameEn: "",
            nameCn: "",
            menuImageUrl: "",
            seq: 1,
            key: this.createUniqueString(),
          },
        ],
      },
    };
  },
  methods: {
    createUniqueString,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datas = [];
          datas = this.form.items.map((item) => {
            delete item.key;
            return item;
          });
          this.submitting = true;
          PostMosaicTechniques(datas)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("新增成功");
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("批量新增异常", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    initForm() {
      this.form = {
        items: [
          {
            nameCn: "",
            nameEn: "",
            menuImageUrl: "",
            seq: 1,
            key: this.createUniqueString(),
          },
        ],
      };
    },
    addRow(inputObj = "") {
      this.form.items.push({
        nameCn: "",
        nameEn: "",
        menuImageUrl: "",
        seq: this.form.items.length + 1,
        key: this.createUniqueString(),
      });
      this.$nextTick(() => {
        if (this.$refs[inputObj]) {
          this.$refs[inputObj][0].focus();
        }
      });
    },
    onDeleteRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      }
    },
    onCancel() {
      this.initForm();
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-img {
  width: 100px;
  height: 100px;
}
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
.box-card {
  margin-bottom: 10px;
}
.multiple-ruleForm {
  padding: 0 10px;
}
</style>
