<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <keep-alive><FilterSection @change="onFilterChange"/></keep-alive>
    <BasicTable
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          size="small"
          class="mt-r"
          type="primary"
          icon="el-icon-document-add"
          @click="multipleFormDialog = true"
          v-if="checkPermission(['PRODUCT_ADMIN'])"
          >新增</el-button
        >
        <el-checkbox class="mt-l mt-r" v-model="showDisable" @change="onSearch"
          >显示禁用</el-checkbox
        >
        &nbsp; &nbsp;
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'lastModifiedAt'">{{
              scope.row.lastModifiedAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'disabled'">
              <el-tag
                :type="scope.row.disabled ? 'danger' : 'success'"
                @click="toggleDisableRow(scope.row, scope.$index)"
                >{{ scope.row.disabled ? "已禁用" : "已启用" }}</el-tag
              >
            </span>

            <el-image
              v-else-if="column.prop === 'menuImageUrl' && !scope.row.isEdit"
              style="width: 80px; height: 80px;"
              :src="scope.row.menuImageUrl"
              :preview-src-list="images"
            >
            </el-image>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-dropdown>
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="scope.row.canUpdate"
                  @click.native.prevent="editRow(scope.row, scope.$index)"
                  >编辑</el-dropdown-item
                >

                <el-dropdown-item
                  v-if="scope.row.canDisable"
                  :class="[scope.row.disabled ? 'text-success' : 'text-danger']"
                  @click.native.prevent="
                    toggleDisableRow(scope.row, scope.$index)
                  "
                >
                  {{ scope.row.disabled ? "启用" : "禁用" }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          >批量启用 {{ multipleSelected.length }}</el-button
        >
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          >批量禁用 {{ multipleSelected.length }}</el-button
        >
      </template>
    </BasicTable>
    <el-drawer
      title="编辑"
      :visible.sync="formDialog"
      append-to-body
      direction="rtl"
      size="500px"
      ><Form :sid="currentId" @cancel="onFormCancel" @success="onFormSuccess"
    /></el-drawer>

    <el-drawer
      title="新增"
      :visible.sync="multipleFormDialog"
      append-to-body
      direction="rtl"
      size="50%"
    >
      <MultipleForm @cancel="onFormCancel" @success="onFormSuccess" />
    </el-drawer>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import {
  GetMosaicTechniquesList,
  ToogleDisableByIds,
  GetUpdateCheck,
  GetDiasbleCheck,
} from "./api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import {
  GetTableOrderParams,
  onClearFilter,
  FormatMultipleItemIds,
  GetTableDisableMessage,
} from "@/utils/table";
import Form from "./components/Form";
import MultipleForm from "./components/MultipleForm";
import PageHeader from "@/components/PageHeader";
import FilterSection from "./components/FilterSection";
import config from "@/config";
export default {
  components: {
    BasicTable,
    Form,
    FilterSection,
    MultipleForm,
    PageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "seq",
      orderDirection: "descending",
      currentId: null,
      multipleSelected: [],
      showDisable: false,
      orderPropObj: {},
    };
  },
  computed: {
    images() {
      return this.tableData.map((item) => item.menuImageUrl);
    },
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "menuImageUrl",
            label: "图片",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "nameCn",
            label: "中文名",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "nameEn",
            label: "英文名",
            minWidth: 120,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },

          {
            prop: "id",
            label: "ID",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "seq",
            label: "次序",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 200,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            showColumn: true,
            align: "center",
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "更新日期",
            showColumn: true,
            width: 200,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "更新人",
            showColumn: true,
            align: "center",
            minWidth: 150,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            align: "center",
            showColumn: true,
            label: "操作",
            width: 100,
          },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },

    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetMosaicTechniquesList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        disabled: this.showDisable,
        ...this.tableFilters,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDisable: true,
              isEdit: false,
            };
          });
          this.tableData.forEach((item) => {
            this.onCheckUpdate(item);
            this.onCheckDisbale(item);
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onSearch();
    },
    onClearAndRefresh() {
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onResetFilter();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.page = 0;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.currentId = null;
      this.formDialog = true;
    },
    editRow(row) {
      if (!row.canUpdate) {
        this.$message.warning("没有权限执行此操作!");
        return false;
      }
      this.currentId = row.id;
      this.formDialog = true;
    },
    onCheckUpdate(item) {
      GetUpdateCheck(item.id)
        .then((res) => {
          item.canUpdate = res.data;
        })
        .catch(() => {
          item.canUpdate = true;
        });
    },
    onCheckDisbale(item) {
      GetDiasbleCheck([item.id])
        .then((res) => {
          item.canDisable = res.data;
        })
        .catch(() => {
          item.canDisable = true;
        });
    },
    onFormCancel() {
      this.currentId = null;
      this.multipleFormDialog = false;
      this.formDialog = false;
    },
    // 批量：启用/禁用
    onMultipleToggleDisable(flag = false) {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let { ids, nameString, unableControl } = FormatMultipleItemIds({
        multipleSelectes: this.multipleSelected,
        nameKey: "nameCn",
      });
      let message = GetTableDisableMessage({
        total: this.multipleSelected.length,
        ableControlCount: ids.length,
        unableControlCount: unableControl.length,
        nameString: nameString,
        flag: flag,
        ableText: "启用",
        unAbleText: "禁用",
      });
      this.$msgbox({
        title: `${flag ? "禁用" : "启用"}提示`,
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (ids.length <= 0) {
              this.$message.info("无有效数据可操作");
              done();
              return;
            }
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToogleDisableByIds(ids, flag)
              .then(() => {
                this.$message.success("操作成功");
                this.multipleSelected = [];
                this.onRefresh();
              })
              .catch((err) => {
                ShowApiError(err, "操作请求错误");
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },

    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    toggleDisableRow(row, index) {
      if (row.canDisable) {
        ToogleDisableByIds([row.id], !row.disabled).then(() => {
          row.disabled = !row.disabled;
          this.$set(this.tableData, index, row);
          this.$message.success("操作成功!");
        });
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },

    onFormSuccess() {
      if (isEmprty(this.currentId)) {
        this.page = 0;
      }
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-image__inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
